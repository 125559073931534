import { IForm } from '@/base-ui/form/types'

export const searchFormConfig: IForm = {
  formItems: [
    {
      field: 'name',
      type: 'input',
      placeholder: '姓名'
    },
    {
      field: 'phone',
      type: 'input',
      placeholder: '手机号'
    }
  ]
}
