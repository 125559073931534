export const contentTableConfig = {
  propList: [
    { prop: 'name', label: '姓名' },
    { prop: 'phone', label: '手机号' },
    { prop: 'create_time', label: '申请时间' },
    { label: '是否有团队/团队成员', slotName: 'spreaders' },
    { label: '佣金', slotName: 'commission' },
    { label: '操作', slotName: 'handler' }
  ]
}
